<template>
    <ion-card class="requestor">
        <ion-card-header>
            <ion-card-title class="requestor__card-title">{{
                $t("registerWizard.requestorData")
            }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <VForm
                :initialValues="registerModel.requestor"
                :validation-schema="validationSchema"
                v-slot="{ values, validate }"
            >
                <ion-item class="ion-no-padding">
                    <ion-label position="stacked" class="requestor__label mb-3">
                        {{ $t("registerWizard.firstName") }}
                    </ion-label>
                    <VField
                        name="firstName"
                        v-slot="{ field }"
                        :label="$t('registerWizard.firstName')"
                    >
                        <ion-input
                            type="text"
                            v-bind="field"
                            name="firstName"
                        ></ion-input>
                    </VField>
                </ion-item>
                <VErrorMessage name="firstName" />

                <ion-item class="ion-no-padding">
                    <ion-label position="stacked" class="requestor__label mb-3">
                        {{ $t("registerWizard.lastName") }}
                    </ion-label>
                    <VField
                        name="lastName"
                        v-slot="{ field }"
                        :label="$t('registerWizard.lastName')"
                    >
                        <ion-input
                            type="text"
                            v-bind="field"
                            name="lastName"
                        ></ion-input>
                    </VField>
                </ion-item>
                <VErrorMessage name="lastName" />

                <ion-item class="ion-no-padding">
                    <ion-label
                        position="stacked"
                        class="requestor__label mb-3"
                        >{{ $t("registerWizard.fiscalCode") }}</ion-label
                    >
                    <VField
                        name="fiscalCode"
                        v-slot="{ field }"
                        :label="$t('registerWizard.fiscalCode')"
                    >
                        <ion-input
                            type="text"
                            v-bind="field"
                            name="fiscalCode"
                            maxlength="16"
                        ></ion-input>
                    </VField>
                </ion-item>
                <VErrorMessage name="fiscalCode" />

                <ion-item class="ion-no-padding">
                    <ion-label
                        position="stacked"
                        class="requestor__label mb-3"
                        :label="$t('registerWizard.phone')"
                    >
                        {{ $t("registerWizard.phone") }}
                    </ion-label>
                    <VField
                        name="phone"
                        v-slot="{ field }"
                        :label="$t('registerWizard.phone')"
                    >
                        <ion-input
                            @change="phoneChanged"
                            type="tel"
                            v-bind="field"
                            name="phone"
                        ></ion-input>
                    </VField>
                </ion-item>
                <VErrorMessage name="phone" />

                <div class="requestor__card gray-card">
                    <div class="requestor__card-title">
                        {{ $t("registerWizard.credentials") }}
                    </div>
                    <div class="mt-2">
                        {{ $t("registerWizard.credentialsDesc") }}
                    </div>

                    <ion-item color="#eaeaea" class="ion-no-padding">
                        <ion-label
                            position="stacked"
                            class="requestor__label mb-3"
                            >{{ $t("registerWizard.email") }}
                        </ion-label>
                        <VField name="email" v-slot="{ field }">
                            <ion-input
                                class="custom-input"
                                :placeholder="$t('registerWizard.email')"
                                type="email"
                                v-bind="field"
                                name="email"
                            ></ion-input>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="email" />

                    <ion-item color="#eaeaea" class="ion-no-padding">
                        <ion-label position="stacked" class="mb-3"> </ion-label>
                        <VField
                            name="confirmEmail"
                            v-slot="{ field }"
                            :label="$t('registerWizard.confirmEmail')"
                        >
                            <ion-input
                                class="custom-input"
                                :placeholder="$t('registerWizard.confirmEmail')"
                                type="email"
                                v-bind="field"
                                name="confirmEmail"
                            ></ion-input>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="confirmEmail" />

                    <ion-item color="#eaeaea" class="ion-no-padding">
                        <ion-label
                            position="stacked"
                            class="requestor__label mb-3"
                            >{{ $t("registerWizard.password") }}
                        </ion-label>
                        <VField name="password" v-slot="{ field }">
                            <ion-input
                                class="custom-input"
                                :placeholder="$t('password')"
                                type="password"
                                v-bind="field"
                                name="password"
                            ></ion-input>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="password" />

                    <ion-item color="#eaeaea" class="ion-no-padding">
                        <ion-label position="stacked" class="mb-2"> </ion-label>
                        <VField
                            name="confirmPassword"
                            v-slot="{ field }"
                            :label="$t('registerWizard.confirmPassword')"
                        >
                            <ion-input
                                class="custom-input"
                                :placeholder="
                                    $t('registerWizard.confirmPassword')
                                "
                                type="password"
                                v-bind="field"
                                name="confirmPassword"
                            ></ion-input>
                        </VField>
                    </ion-item>
                    <VErrorMessage name="confirmPassword" />

                    <div class="mt-3 requestor__card-info">
                        {{ $t("registerWizard.passwordHelp") }}
                    </div>
                </div>

                <div class="requestor__card-title mt-5">
                    {{ $t("registerWizard.identityCard") }}
                </div>
                <div class="mt-2">
                    {{ $t("registerWizard.identityCardDesc") }}
                </div>

                <ion-item class="ion-no-padding">
                    <ion-label position="stacked" class="requestor__label mb-3"
                        >{{ $t("registerWizard.documentType") }}
                    </ion-label>
                    <VField
                        name="documentType"
                        :label="$t('registerWizard.documentType')"
                        v-slot="{ field }"
                    >
                        <ion-select
                            value="{hairColor}"
                            :placeholder="$t('select')"
                            v-bind="field"
                            :ok-text="$t('select')"
                            :cancel-text="$t('cancel')"
                            name="documentType"
                        >
                            <ion-select-option :value="DocumentType.Idcard">
                                {{
                                    $t("registerWizard.documentTypeEnum.idcard")
                                }}
                            </ion-select-option>
                            <ion-select-option :value="DocumentType.Passport">
                                {{
                                    $t(
                                        "registerWizard.documentTypeEnum.passport"
                                    )
                                }}
                            </ion-select-option>
                            <ion-select-option :value="DocumentType.Drivers">
                                {{
                                    $t(
                                        "registerWizard.documentTypeEnum.drivers"
                                    )
                                }}
                            </ion-select-option>
                            <ion-select-option :value="DocumentType.Staypermit">
                                {{
                                    $t(
                                        "registerWizard.documentTypeEnum.staypermit"
                                    )
                                }}
                            </ion-select-option>
                        </ion-select>
                    </VField>
                </ion-item>
                <VErrorMessage name="documentType" />

                <div class="ion-no-padding mt-2">
                    <input
                        v-show="false"
                        type="file"
                        @change="(e) => previewFiles(e, 1)"
                        accept="image/*"
                        ref="chosenDocumentFrontInput"
                    />
                    <ion-button
                        v-if="!registerModel.requestorDocuments.documentFront"
                        class="text-black"
                        fill="outline"
                        @click="takePhoto(1)"
                    >
                        <ion-icon :icon="iconCamera" />
                        <span class="ml-2">{{
                            $t("registerWizard.uploadFront")
                        }}</span>
                    </ion-button>
                    <div
                        v-if="registerModel.requestorDocuments.documentFront"
                        class="d-flex align-items-center"
                    >
                        <div class="text-uppercase mr-1">
                            {{ $t("registerWizard.front") }}:
                        </div>
                        <div @click="toggleShowImagePreview(true, 1)">
                            {{
                                registerModel.requestorDocuments.documentFront
                                    .fileName
                            }}
                        </div>
                        <div class="ml-2">
                            <ion-button
                                class="text-black"
                                size="small"
                                fill="clear"
                                @click="deletePhoto(1)"
                                ><IconTrashRed class="mr-1" />{{
                                    $t("deletePhoto")
                                }}
                            </ion-button>
                        </div>
                    </div>
                </div>

                <div class="ion-no-padding">
                    <input
                        v-show="false"
                        type="file"
                        @change="(e) => previewFiles(e, 2)"
                        accept="image/*"
                        ref="chosenDocumentBackInput"
                    />
                    <ion-button
                        v-if="!registerModel.requestorDocuments.documentBack"
                        class="text-black"
                        fill="outline"
                        @click="takePhoto(2)"
                    >
                        <ion-icon :icon="iconCamera" />
                        <span class="ml-2">{{
                            $t("registerWizard.uploadBack")
                        }}</span>
                    </ion-button>
                    <div
                        v-if="registerModel.requestorDocuments.documentBack"
                        class="d-flex align-items-center"
                    >
                        <div class="text-uppercase mr-1">
                            {{ $t("registerWizard.back") }}:
                        </div>
                        <div @click="toggleShowImagePreview(true, 2)">
                            {{
                                registerModel.requestorDocuments.documentBack
                                    .fileName
                            }}
                        </div>
                        <div class="ml-2">
                            <ion-button
                                class="text-black"
                                size="small"
                                fill="clear"
                                @click="deletePhoto(2)"
                                ><IconTrashRed class="mr-1" />{{
                                    $t("deletePhoto")
                                }}
                            </ion-button>
                        </div>
                    </div>
                </div>

                <div class="requestor__card-title mt-5">
                    {{ $t("registerWizard.uploadYourPhoto") }}
                </div>
                <div class="mt-2">
                    {{ $t("registerWizard.uploadYourPhotoDesc") }}
                </div>

                <div class="ion-no-padding text-center mt-2">
                    <div>
                        <img
                            v-if="
                                registerModel.requestorDocuments.selfieImage
                                    ?.base64
                            "
                            class="mt-3 user-pic"
                            width="180"
                            height="180"
                            :src="
                                registerModel.requestorDocuments.selfieImage
                                    .base64
                            "
                        />
                        <img
                            v-else
                            class="mt-1"
                            width="195"
                            height="195"
                            src="@/assets/images/user-placeholder.jpg"
                        />
                    </div>

                    <input
                        v-show="false"
                        type="file"
                        @change="(e) => previewFiles(e, 3)"
                        accept="image/*"
                        ref="chosenSelfieInput"
                    />
                    <ion-button
                        v-if="!registerModel.requestorDocuments.selfieImage"
                        class="text-black"
                        fill="outline"
                        @click="takePhoto(3)"
                    >
                        <ion-icon :icon="iconCamera" />
                        <span class="ml-2">{{
                            $t("registerWizard.uploadPhoto")
                        }}</span>
                    </ion-button>
                    <ion-button
                        v-else
                        class="text-black"
                        size="small"
                        fill="clear"
                        @click="deletePhoto(3)"
                        ><IconTrashRed class="mr-1" />{{ $t("deletePhoto") }}
                    </ion-button>
                </div>

                <ion-button
                    color="primary"
                    expand="block"
                    class="mx-0 mt-4"
                    @click="submit(values, validate)"
                >
                    {{ $t("registerWizard.next") }}
                </ion-button>
            </VForm>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepRequestorData.ts"></script>
<style lang="scss" src="./StepRequestorData.scss"></style>
