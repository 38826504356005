<template>
    <ul class="stepper mt-4 mb-4">
        <li
            v-for="step in steps"
            :key="`step-${step.value}`"
            class="stepper__step"
            :class="{
                'stepper__step--active': currentStep === step.activeClass,
                'stepper__step--completed': currentStep > step.completedClass,
            }"
        >
            <span class="stepper__step-index">{{ step.value }}</span>
            <span
                class="stepper__step-desc"
                :class="{ 'active-title': currentStep >= step.activeClass }"
                >{{ step.title }}</span
            >
        </li>
    </ul>
</template>

<script lang="ts" src="./Stepper.ts"></script>
<style lang="scss" src="./Stepper.scss"></style>
