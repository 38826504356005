import { extractFiscalCodeDataClient } from '@/core/api/fiscal-code-service';
import {
    FiscalCode,
    FiscalCodeData,
    RelativeRegistration,
    UploadRegistrationFile,
} from '@/../client-generator/generated-clients/api-clients';
import { Vue, Options } from 'vue-class-component';
import { RegisterModel, Document } from '../../models/RegisterModel';
import { checkmarkOutline as iconCheck, closeOutline as iconClose, camera as iconCamera } from 'ionicons/icons';
import { alertController, modalController } from '@ionic/vue';
import PreviewImageModalDedication from '@/views/pages/Timeline/TimelineModalNewDedication/PreviewImageModalDedication/PreviewImageModalDedication.vue';
import registrationUploadClient from '@/core/api/registration-upload-service';
import { message } from '@/core/utilities/utilities';
import registrationClient from '@/core/api/registration-service';
import { PatientPlace, WizardStep } from '../../models/RegisterEnums';

@Options({
    name: 'StepOverview',
    emits: ['go-next', 'change-step'],
    components: {
        PreviewImageModalDedication,
    },
    props: {
        registerModel: {
            type: Object as () => RegisterModel,
            required: true,
        },
    },
})
export default class StepOverview extends Vue {
    iconCheck = iconCheck;
    iconClose = iconClose;
    iconCamera = iconCamera;
    message = message;
    registerModel!: RegisterModel;
    requestorData: FiscalCodeData | null = null;
    patientData: FiscalCodeData | null = null;

    privacyPolicyUrl = process.env.VUE_APP_PRIVACY_POLICY_URL;

    async mounted() {
        this.requestorData = await this.fetchRequestorData(this.registerModel.requestor.fiscalCode);
        this.patientData = await this.fetchRequestorData(this.registerModel.patient.fiscalCode);
    }

    async fetchRequestorData(fiscalCode: string) {
        let response: FiscalCodeData | null = null;
        if (!fiscalCode) return response;
        try {
            response = await extractFiscalCodeDataClient.extractFiscalCodeData(new FiscalCode({ fiscalCode }));
        } catch (error) {
            this.message(this.$t('apiError'));
        }
        return response;
    }

    async register() {
        const uuidDocumentFront = await this.uploadDocument(this.registerModel.requestorDocuments.documentFront);
        const uuidDocumentBack = await this.uploadDocument(this.registerModel.requestorDocuments.documentBack);
        const uuidSelfie = await this.uploadDocument(this.registerModel.requestorDocuments.selfieImage);

        try {
            if (
                uuidDocumentFront &&
                uuidDocumentBack &&
                uuidSelfie &&
                this.registerModel.requestor.documentType &&
                this.registerModel.patient.relation
            ) {
                const data: RelativeRegistration = new RelativeRegistration({
                    email: this.registerModel.requestor.email,
                    firstName: this.registerModel.requestor.firstName,
                    lastName: this.registerModel.requestor.lastName,
                    password: this.registerModel.requestor.password,
                    birthMunicipality: this.requestorData?.birthCity,
                    fiscalCode: this.registerModel.requestor.fiscalCode,
                    phone: this.registerModel.requestor.phone,
                    documentType: this.registerModel.requestor.documentType,
                    relation: this.registerModel.patient.relation,
                    patientFirstName: this.registerModel.patient.firstName,
                    patientLastName: this.registerModel.patient.lastName,
                    patientBirthCity: this.patientData?.birthCity,
                    patientFiscalCode: this.registerModel.patient.fiscalCode,
                    smsToken: this.registerModel.smsToken,
                    confirmCriminalPenalties: this.registerModel.confirmCriminalPenalties,
                    confirmClosestRelative: this.registerModel.confirmClosestRelative,
                    confirmPersonalData: this.registerModel.confirmPersonalData,
                    confirmPrivacy: this.registerModel.confirmPrivacy,
                    isEmergencyRoom: this.registerModel.tempData.patientPlace === PatientPlace.EMERGENCY_ROOM,
                    confirmEmail: this.registerModel.confirmEmail,
                    token: this.registerModel.tempData.transferCode ?? undefined,
                    confirmDataIsCorrect: this.registerModel.confirmDataIsCorrect,
                    confirmServiceActivation: this.registerModel.confirmServiceActivation,
                    confirmTermsAndAgreements: this.registerModel.confirmTermsAndAgreements,
                    documentBack: uuidDocumentBack,
                    documentFront: uuidDocumentFront,
                    selfie: uuidSelfie,
                });
                await registrationClient.registration(data);
                this.$emit('change-step', WizardStep.THANKYOU);
            }
        } catch (error) {
            if (error.status === 400) {
                let message = '';
                if (
                    error.response.nonFieldErrors.includes('fiscal-code-no-match-with-name') ||
                    error.response.nonFieldErrors.includes('fiscal-code-no-match-with-surname')
                ) {
                    message = this.$t('fiscalCodeNoMatchWithName');
                } else if (error.response.nonFieldErrors.includes('fiscal-code-not-valid')) {
                    message = this.$t('invalidFiscalCode');
                } else {
                    message = this.$t('backendValidationError');
                }
                const alert = await alertController.create({
                    header: this.$t('warning'),
                    message: message,
                    buttons: [this.$t('ok')],
                });
                return alert.present();
            } else {
                this.message(this.$t('apiError'));
            }
        }
    }

    async uploadDocument(doc: Document | null) {
        let response: UploadRegistrationFile | null = null;
        try {
            if (doc) response = await registrationUploadClient.uploadPostCustom(doc.base64, doc.fileName);
        } catch (error) {
            this.message(this.$t('apiError'));
        }
        return response && response.uuid ? response.uuid : null;
    }

    async toggleShowImagePreview(doShow: boolean, componentValue = 1) {
        if (doShow) {
            const modal = await modalController.create({
                component: PreviewImageModalDedication,
                componentProps: {
                    webPath:
                        componentValue === 1
                            ? this.registerModel.requestorDocuments.documentFront?.base64
                            : this.registerModel.requestorDocuments.documentBack?.base64,
                },
            });
            (window as any).currentModalImage = modal;
            modal.present();
        } else {
            if ((window as any).currentModalImage) await (window as any).currentModalImage.dismiss();
        }
    }
}
