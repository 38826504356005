import { sendVerificationSmsClient, verifySmsTokenClient } from '@/core/api/sms-token-service';
import { alertController } from '@ionic/vue';
import { SendSmsVerification, VerifySmsTokenRequest } from '@/../client-generator/generated-clients/api-clients';
import { PropType } from 'vue';
import { Vue, Options } from 'vue-class-component';
import { RegisterModel } from '../../models/RegisterModel';

@Options({
    name: 'StepOtp',
    props: {
        registerModel: {
            type: Object as PropType<RegisterModel>,
            required: true,
        },
    },
    emits: ['goNext'],
    watch: {
        smsOtpAlias() {
            this.codeSubmitted = false;
        },
    },
})
export default class StepOtp extends Vue {
    registerModel!: RegisterModel;
    isCodeValid = false;
    codeSubmitted = false;
    minSmsOtpLength = 1;
    lastSent: Date | null = null;

    get nextDisabled() {
        return this.registerModel.tempData.smsOtp.length < this.minSmsOtpLength || (this.codeSubmitted && !this.isCodeValid);
    }

    get smsOtpAlias() {
        return this.registerModel.tempData.smsOtp;
    }

    mounted() {
        this.registerModel.tempData.smsOtp = '';
        this.sendSmsOtp(false);
    }

    async sendSmsOtp(confirm: boolean) {
        if (this.lastSent && (new Date().getTime() - this.lastSent.getTime()) / 1000 < 60) {
            const alert = await alertController.create({
                header: this.$t('registerWizard.otpStep.errorSendingCode'),
                message: this.$t('registerWizard.otpStep.waitOneMinuteToResend'),
                buttons: [
                    {
                        text: this.$t('ok'),
                    },
                ],
            });
            alert.present();
            return;
        }
        try {
            await sendVerificationSmsClient.sendVerificationSms(
                new SendSmsVerification({
                    phone: this.registerModel.requestor.phone,
                })
            );
            this.lastSent = new Date();
            if (confirm) {
                const alert = await alertController.create({
                    header: this.$t('registerWizard.otpStep.codeSent'),
                    message: this.$t('registerWizard.otpStep.codeSentMessage', { phone: this.registerModel.requestor.phone }),
                    buttons: [
                        {
                            text: this.$t('ok'),
                        },
                    ],
                });
                alert.present();
            }
        } catch (error) {
            const alert = await alertController.create({
                header: this.$t('registerWizard.otpStep.errorSendingCode'),
                message: error.response?.phone?.[0],
                buttons: [
                    {
                        text: this.$t('ok'),
                    },
                ],
            });
            alert.present();
        }
    }

    async checkOtp() {
        if (this.registerModel.tempData.smsOtp.length < this.minSmsOtpLength) {
            this.codeSubmitted = false;
            this.isCodeValid = false;
            return;
        }

        try {
            const response = await verifySmsTokenClient.verifySmsToken(
                new VerifySmsTokenRequest({
                    phone: this.registerModel.requestor.phone,
                    verificationToken: this.registerModel.tempData.smsOtp,
                })
            );
            this.registerModel.requestor.phone = response.phoneNumber;
            this.registerModel.smsToken = response.token;
            this.isCodeValid = true;
        } catch (error) {
            this.isCodeValid = false;
        }
        this.codeSubmitted = true;
    }

    async goNext() {
        await this.checkOtp();
        if (this.isCodeValid) {
            this.$emit('go-next');
        }
    }
}
