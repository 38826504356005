<template>
    <h3 class="text-center mb-3">{{ $t("registerWizard.otpStep.title") }}</h3>
    <ion-card>
        <ion-card-content>
            <p>
                {{
                    $t("registerWizard.otpStep.description", {
                        phone: registerModel.requestor.phone,
                    })
                }}
            </p>
            <ion-item class="ion-no-padding">
                <ion-label position="stacked" class="fw-bold mb-2">{{
                    $t("registerWizard.otpStep.insertCode")
                }}</ion-label>
                <ion-input
                    type="text"
                    :placeholder="$t('registerWizard.otpStep.insertCode')"
                    v-model="registerModel.tempData.smsOtp"
                    class="is-valid"
                ></ion-input>
            </ion-item>
            <template v-if="codeSubmitted">
                <div v-if="isCodeValid" class="text-success">
                    {{ $t("registerWizard.otpStep.validCode") }}
                </div>
                <div v-else class="text-danger">
                    {{ $t("registerWizard.otpStep.invalidCode") }}
                </div>
            </template>
            <div class="mt-2">
                {{ $t("registerWizard.otpStep.codeNotReceived") }}
                <a
                    href="#"
                    class="fw-bold text-decoration-none"
                    @click.prevent="sendSmsOtp(true)"
                    >{{ $t("registerWizard.otpStep.sendAgain") }}</a
                >
            </div>
            <ion-button
                color="primary"
                class="w-100 mt-5"
                :disabled="nextDisabled"
                @click="goNext"
            >
                {{ $t("next") }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepOtp.ts"></script>
<style lang="scss" src="./StepOtp.scss"></style>
