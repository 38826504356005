<template>
    <ion-page>
        <ion-content padding class="page-register">
            <div
                class="page-register__container d-flex flex-column ion-justify-content-center"
            >
                <Stepper :currentStep="step" v-show="stepperVisible" />
                <div class="step-content">
                    <component
                        :is="stepComponent"
                        :registerModel="registerModel"
                        @change-step="changeStep"
                        @go-next="goNext"
                    />
                </div>
                <div
                    class="text-center mt-3 fw-bold back-to-btn mb-4"
                    v-show="
                        step !== WizardStep.REQUESTOR_DATA &&
                        step !== WizardStep.THANKYOU
                    "
                >
                    <button
                        type="button"
                        class="btn btn-link fw-bold bg-transparent"
                        @click="changeStep(WizardStep.REQUESTOR_DATA)"
                    >
                        {{ $t("registerWizard.returnToMyData") }}
                    </button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./Register.ts"></script>
<style lang="scss" src="./Register.scss"></style>
