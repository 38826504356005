<template>
    <ion-card class="overview__card">
        <ion-card-content>
            <div class="overview__label mb-3">
                {{ $t("registerWizard.overviewTitle") }}
            </div>
            <div class="overview__card gray-card">
                <div>
                    {{ $t("registerWizard.myself") }}
                    <strong class="text-uppercase">
                        {{ registerModel.requestor.firstName }}
                        {{ registerModel.requestor.lastName }}
                    </strong>
                </div>
                <div>
                    (CF <strong>{{ registerModel.requestor.fiscalCode }}</strong
                    >)
                </div>
                <div v-if="requestorData">
                    {{ $t("registerWizard.bornAt") }}
                    <strong class="text-uppercase">{{
                        requestorData?.birthCity
                    }}</strong>
                    il
                    <strong>{{
                        requestorData?.birthDate.toLocaleDateString()
                    }}</strong
                    >,
                </div>
                <div>
                    {{ $t("registerWizard.overviewPhone") }}
                    <strong>{{ registerModel.requestor.phone }}</strong>
                </div>
            </div>

            <h2 class="divider line one-line">
                {{ $t("registerWizard.ask") }}
            </h2>

            <div class="overview__card-info mt-2">
                <span v-html="$t('registerWizard.activationMessage')"></span>
            </div>

            <h2 class="divider line one-line">
                {{ $t("registerWizard.overviewDeclareToBe") }}
            </h2>

            <div class="overview__card gray-card">
                <div>
                    <strong class="text-uppercase">{{
                        $t(
                            `registerWizard.relationType.${registerModel.patient.relation}`
                        )
                    }}</strong>
                    {{ $t("registerWizard.overviewRelation") }}
                    <strong class="text-uppercase">
                        {{ registerModel.patient.firstName }}
                        {{ registerModel.patient.lastName }}
                    </strong>
                </div>
                <div>
                    (CF <strong>{{ registerModel.patient.fiscalCode }}</strong
                    >)
                </div>
                <div v-if="patientData">
                    {{ $t("registerWizard.bornAt") }}
                    <strong class="text-uppercase">{{
                        patientData?.birthCity
                    }}</strong>
                    il
                    <strong>{{
                        patientData?.birthDate.toLocaleDateString()
                    }}</strong>
                </div>
            </div>

            <h2 class="divider line one-line">
                {{ $t("registerWizard.alsoDeclare") }}
            </h2>

            <div class="ion-no-padding d-flex">
                <div class="mr-2 mt-1"><ion-icon :icon="iconCheck" /></div>
                <ion-label
                    class="ion-text-wrap"
                    v-html="$t('registerWizard.confirmClosestRelative')"
                />
            </div>

            <div class="ion-no-padding d-flex mt-3">
                <div class="mr-2 mt-1"><ion-icon :icon="iconCheck" /></div>
                <ion-label class="ion-text-wrap">
                    <span v-html="$t('registerWizard.confirmPersonalData')" />
                    <a class="link" :href="privacyPolicyUrl" target="_blank"
                        >{{ $t("registerWizard.privacyPolicy") }} </a
                    ><span>&#41;;</span>
                </ion-label>
            </div>

            <div class="ion-no-padding d-flex mt-3">
                <div class="mr-2 mt-1"><ion-icon :icon="iconCheck" /></div>
                <ion-label
                    v-html="$t('registerWizard.confirmPrivacy')"
                    class="ion-text-wrap"
                />
            </div>

            <div class="ion-no-padding d-flex mt-3">
                <div class="mr-2 mt-1">
                    <ion-icon
                        :icon="
                            registerModel.confirmEmail ? iconCheck : iconClose
                        "
                    />
                </div>
                <ion-label class="ion-text-wrap">
                    <span v-html="$t('registerWizard.confirmEmailText')" />
                    <strong>{{ registerModel.requestor.email }}</strong>
                </ion-label>
            </div>

            <h2 class="divider line one-line mt-5">
                {{ $t("registerWizard.identityCard") }}
            </h2>

            <div class="overview__card gray-card">
                <div>
                    {{ $t("registerWizard.attach") }}
                    <strong class="text-uppercase">
                        {{
                            $t(
                                `registerWizard.documentTypeEnum.${registerModel.requestor.documentType}`
                            )
                        }}</strong
                    >
                </div>
                <div>
                    <div>
                        <ion-button
                            v-if="
                                registerModel.requestorDocuments.documentFront
                            "
                            class="text-black"
                            fill="clear"
                            @click="toggleShowImagePreview(true, 1)"
                        >
                            <ion-icon :icon="iconCamera" />
                            <span class="ml-2 link">
                                {{
                                    registerModel.requestorDocuments
                                        .documentFront.fileName
                                }}</span
                            >
                        </ion-button>
                    </div>
                    <div>
                        <ion-button
                            v-if="registerModel.requestorDocuments.documentBack"
                            class="text-black"
                            fill="clear"
                            @click="toggleShowImagePreview(true, 2)"
                        >
                            <ion-icon :icon="iconCamera" />
                            <span class="ml-2 link">
                                {{
                                    registerModel.requestorDocuments
                                        .documentBack.fileName
                                }}</span
                            >
                        </ion-button>
                    </div>
                </div>
            </div>

            <ion-item class="ion-no-padding align-items-start mt-4">
                <ion-label
                    class="ion-text-wrap custom-label"
                    v-html="$t('registerWizard.overviewConfirmDataIsCorrect')"
                />
                <ion-checkbox
                    color="success"
                    mode="md"
                    class="mt-3"
                    v-model="registerModel.confirmDataIsCorrect"
                    slot="start"
                />
            </ion-item>

            <ion-item class="ion-no-padding align-items-start">
                <ion-label
                    class="ion-text-wrap custom-label"
                    v-html="$t('registerWizard.overviewConfirm')"
                />
                <ion-checkbox
                    color="success"
                    mode="md"
                    class="mt-3"
                    v-model="registerModel.confirmServiceActivation"
                    slot="start"
                />
            </ion-item>

            <ion-item class="ion-no-padding align-items-start">
                <ion-label class="ion-text-wrap custom-label"
                    >{{ $t("registerWizard.overviewConfirmPrivacy") }}
                    <a class="link" :href="privacyPolicyUrl" target="_blank"
                        >{{ $t("privacyPolicy") }}
                    </a>
                </ion-label>
                <ion-checkbox
                    color="success"
                    mode="md"
                    class="mt-3"
                    v-model="registerModel.confirmTermsAndAgreements"
                    slot="start"
                />
            </ion-item>

            <ion-button
                color="primary"
                expand="block"
                :disabled="
                    !(
                        registerModel.confirmTermsAndAgreements &&
                        registerModel.confirmServiceActivation &&
                        registerModel.confirmDataIsCorrect
                    )
                "
                class="mx-0 mt-4 text-uppercase"
                @click="register()"
            >
                {{ $t("registerWizard.register") }}
            </ion-button>
        </ion-card-content>
    </ion-card>
</template>

<script lang="ts" src="./StepOverview.ts"></script>
<style lang="scss" src="./StepOverview.scss"></style>
