import { Vue, Options } from 'vue-class-component';
import { IonBadge, IonIcon } from '@ionic/vue';
import IconZupit from '@/views/components/icons/IconZupit.vue';
import StepRequestorData from './WizardItems/StepRequestorData/StepRequestorData.vue';
import StepOtp from './WizardItems/StepOtp/StepOtp.vue';
import StepActivationType from './WizardItems/StepActivationType/StepActivationType.vue';
import TransferManagement from './WizardItems/TransferManagement/TransferManagement.vue';
import StepRequestorRelationship from './WizardItems/StepRequestorRelationship/StepRequestorRelationship.vue';
import StepOverview from './WizardItems/StepOverview/StepOverview.vue';
import StepThankyou from './WizardItems/StepThankyou/StepThankyou.vue';
import Stepper from './Stepper/Stepper.vue';
import { WizardStep } from './models/RegisterEnums';
import { DefaultRegisterModel, RegisterModel } from './models/RegisterModel';

@Options({
    name: 'Register',
    components: {
        IconZupit,
        IonBadge,
        IonIcon,
        StepRequestorData,
        StepOtp,
        StepActivationType,
        TransferManagement,
        StepRequestorRelationship,
        StepOverview,
        StepThankyou,
        Stepper,
    },
})
export default class Register extends Vue {
    WizardStep = WizardStep;
    step: WizardStep = WizardStep.REQUESTOR_DATA;

    registerModel: RegisterModel = JSON.parse(JSON.stringify(DefaultRegisterModel));

    get stepComponent() {
        let stepName = '';
        switch (this.step) {
            case WizardStep.REQUESTOR_DATA:
                stepName = 'StepRequestorData';
                break;
            case WizardStep.OTP:
                stepName = 'StepOtp';
                break;
            case WizardStep.ACTIVATION_TYPE:
                stepName = 'StepActivationType';
                break;
            case WizardStep.QR_CODE:
                stepName = 'TransferManagement';
                break;
            case WizardStep.REQUESTOR_RELATIONSHIP:
                stepName = 'StepRequestorRelationship';
                break;
            case WizardStep.OVERVIEW:
                stepName = 'StepOverview';
                break;
            case WizardStep.THANKYOU:
                stepName = 'StepThankyou';
                break;
        }
        return stepName;
    }

    get stepperVisible() {
        return this.step !== WizardStep.OTP && this.step !== WizardStep.QR_CODE && this.step !== WizardStep.THANKYOU;
    }

    changeStep(newStep: WizardStep) {
        this.step = newStep;
    }

    goNext() {
        this.step++;
        if (this.step === WizardStep.OTP && !!this.registerModel.smsToken) {
            this.step++;
        }
    }
}
