import { Vue, Options } from 'vue-class-component';
import { RegisterModel, Requestor } from '../../models/RegisterModel';

import { camera as iconCamera } from 'ionicons/icons';
import { alertController, IonIcon, modalController } from '@ionic/vue';
import IconTrashRed from '@/views/components/icons/IconTrashRed.vue';
import PreviewImageModalDedication from '@/views/pages/Timeline/TimelineModalNewDedication/PreviewImageModalDedication/PreviewImageModalDedication.vue';
import { RelativeRegistrationDocumentType } from '@/../client-generator/generated-clients/api-clients';

@Options({
    name: 'StepRequestorData',
    components: {
        IonIcon,
        IconTrashRed,
        PreviewImageModalDedication,
    },
    emits: ['go-next'],
    props: {
        registerModel: {
            type: Object as () => RegisterModel,
            required: true,
        },
    },
})
export default class StepRequestorData extends Vue {
    registerModel!: RegisterModel;
    iconCamera = iconCamera;

    DocumentType = RelativeRegistrationDocumentType;

    $refs!: {
        chosenDocumentFrontInput: HTMLFormElement;
        chosenDocumentBackInput: HTMLFormElement;
        chosenSelfieInput: HTMLFormElement;
    };

    validationSchema = {
        firstName: 'required',
        lastName: 'required',
        phone: 'required|phoneNumber',
        fiscalCode: 'required|min:16|fiscalCode',
        email: 'required|email',
        confirmEmail: 'required|email|confirmed:@email',
        password: 'required|min:8|password|specialChar',
        confirmPassword: 'required|min:8|password|confirmed:@password|specialChar',
        documentType: 'required',
    };

    phoneChanged() {
        this.registerModel.smsToken = '';
        this.registerModel.tempData.smsOtp = '';
    }

    async submit(values: Requestor, validateForm: Function) {
        const isValid =
            (await validateForm()) &&
            !!this.registerModel.requestorDocuments.documentFront &&
            !!this.registerModel.requestorDocuments.documentBack &&
            !!this.registerModel.requestorDocuments.selfieImage;
        if (!isValid) {
            const alert = await alertController.create({
                header: this.$t('warning'),
                message: this.$t('requiredFieldsWarn'),
                buttons: [this.$t('ok')],
            });
            return alert.present();
        } else {
            this.registerModel.requestor = { ...values };
            this.$emit('go-next');
        }
    }

    async takePhoto(componentValue: number) {
        switch (componentValue) {
            case 1:
                this.$refs.chosenDocumentFrontInput.click();
                break;
            case 2:
                this.$refs.chosenDocumentBackInput.click();
                break;
            case 3:
                this.$refs.chosenSelfieInput.click();
                break;
        }
    }

    async deletePhoto(componentValue: number) {
        switch (componentValue) {
            case 1:
                this.registerModel.requestorDocuments.documentFront = null;
                break;
            case 2:
                this.registerModel.requestorDocuments.documentBack = null;
                break;
            case 3:
                this.registerModel.requestorDocuments.selfieImage = null;
                break;
        }
    }

    previewFiles(e: any, componentValue: number) {
        const file = e.target.files[0] as File;
        switch (componentValue) {
            case 1:
                this.$refs.chosenDocumentFrontInput.value = null;
                break;
            case 2:
                this.$refs.chosenDocumentBackInput.value = null;
                break;
            case 3:
                this.$refs.chosenSelfieInput.value = null;
                break;
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            switch (componentValue) {
                case 1:
                    this.registerModel.requestorDocuments.documentFront = this.generateDocumentObject(file, reader);
                    break;
                case 2:
                    this.registerModel.requestorDocuments.documentBack = this.generateDocumentObject(file, reader);
                    break;
                case 3:
                    this.registerModel.requestorDocuments.selfieImage = this.generateDocumentObject(file, reader);
                    break;
            }
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    }

    generateDocumentObject(file: File, reader: FileReader) {
        if (reader && reader.result) {
            return {
                base64: reader.result.toString(),
                fileName: file.name.split('.')[0] + '.jpg',
                webPath: '',
            };
        } else return null;
    }

    async toggleShowImagePreview(doShow: boolean, componentValue = 1) {
        if (doShow) {
            const modal = await modalController.create({
                component: PreviewImageModalDedication,
                componentProps: {
                    webPath:
                        componentValue === 1
                            ? this.registerModel.requestorDocuments.documentFront?.base64
                            : this.registerModel.requestorDocuments.documentBack?.base64,
                },
            });
            (window as any).currentModalImage = modal;
            modal.present();
        } else {
            if ((window as any).currentModalImage) await (window as any).currentModalImage.dismiss();
        }
    }
}
