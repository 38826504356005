import { Vue, Options } from 'vue-class-component';
import { WizardStep } from '../models/RegisterEnums';

interface StepList {
    title: string;
    value: number;
    activeClass: WizardStep;
    completedClass: WizardStep;
}

@Options({
    name: 'Stepper',
    props: {
        currentStep: WizardStep,
    },
})
export default class Stepper extends Vue {
    WizardStep = WizardStep;
    currentStep!: WizardStep;

    steps: StepList[] = [];

    created() {
        this.steps = [
            {
                title: this.$t('registerWizard.requestorData'),
                activeClass: WizardStep.REQUESTOR_DATA,
                completedClass: WizardStep.REQUESTOR_DATA,
                value: 1,
            },
            {
                title: this.$t('registerWizard.statements'),
                activeClass: WizardStep.ACTIVATION_TYPE,
                completedClass: WizardStep.ACTIVATION_TYPE,
                value: 2,
            },
            {
                title: this.$t('registerWizard.confirm'),
                activeClass: WizardStep.OVERVIEW,
                completedClass: WizardStep.OVERVIEW,
                value: 3,
            },
        ];
    }
}
